import v from "./version.txt";

const sw = self as unknown as ServiceWorkerGlobalScope;
const version = v.trim();
const cachePrefix = "alcas-";
const cacheKey = `${cachePrefix}${version}`;

sw.addEventListener("install", (event) => {
    console.log("[SW] installing service:", version);

    let p: Promise<void>;
    if (version != "dev") {
        p = caches.open(cacheKey).then((cache) => {
            cache.addAll(["/"]);
        });
    } else {
        p = Promise.resolve();
    }

    event.waitUntil(
        p.then(() => {
            console.log("[SW] service installed, replacing current workers");
            sw.skipWaiting();
        }),
    );
});

sw.addEventListener("activate", (event) => {
    console.log("[SW] activating service:", version);

    const cleanup = async (): Promise<void> => {
        for (const key of await caches.keys()) {
            if (key.startsWith(cachePrefix) && key != cacheKey) {
                console.log("[SW] removing cache entries:", key);
                await caches.delete(key);
            }
        }
    };

    event.waitUntil(cleanup());
});

sw.addEventListener("fetch", async (event) => {
    const request = event.request;
    const bypassCache = version == "dev" || (!request.url.includes("/dist/") && request.url != "/");
    if (bypassCache) {
        event.respondWith(fetch(request));
        return;
    }
    event.respondWith(
        caches.match(request).then((resp) => {
            if (resp) {
                console.log("[SW] cache hit:", request.url);
                return resp;
            }

            return fetch(request).then((resp) => {
                const clone = resp.clone();
                console.log("[SW] caching:", request.url);
                caches.open(cacheKey).then((cache) => cache.put(request, clone));
                return resp;
            });
        }),
    );
});

sw.addEventListener("message", async (event: ExtendableMessageEvent) => {
    if (!event.source) return;

    switch (event.data.type) {
        case "version":
            event.source.postMessage({ type: "version", version: version });
            break;
    }
});
